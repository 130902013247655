export const SuitDataRes = {
  SUITS: "suit_color",
  SHIRTS: "shirt_color",
  TIES: "tie_type",
  SUIT_PACKAGES: "shoe_type",
  TIE_COLORS: "tie_color",
};

export const SuitStatus = {
  PAID: "paid",
  NEED_TO_PAY: "need_to_pay",
};

export const Currency = {
  USD: {
    unit: "USD",
    symbol: "$",
  },
  CAD: {
    unit: "CAD",
    symbol: "C$",
  },
};
