// constants
import { SuitDataRes } from "../constants/index";

import { api } from "@goldfishcode/modern-groom-api-sdk";

export default {
  data: () => ({
    suitMasterData: null,
    suitCombo: {
      suit: 0,
      shirt: 0,
      tie: 0,
      color: 0,
      suitPackage: 0,
    },
  }),

  methods: {
    createSuitCombo: async function() {
      let data;

      try {
        data = await api.Suit.getSuit(this.suitId);
      } catch (error) {
        this.$router.push('/')
        alert(error.message);
        return;
      }

      const arrayStringSuits = this.suitMasterData.suits.map((suit) => suit.id);
      const arrayStringShirt = this.suitMasterData.shirts.map(
        (shirt) => shirt.id
      );
      const arrayStringTieType = this.suitMasterData.ties.types.map(
        (type) => type.id
      );
      const arrayStringTieColor = this.suitMasterData.ties.colors.map(
        (color) => color.id
      );
      const arrayStringSuitPackage = this.suitMasterData.suitPackages.map(
        (item) => item.id
      );
      const indexOfTieColor = arrayStringTieColor.indexOf(
        data.tie_color_obj?.id || data.tie_color
      );

      this.suitCombo = {
        suit: arrayStringSuits.indexOf(
          data.suit_color_obj?.id || data.suit_color
        ),
        shirt: arrayStringShirt.indexOf(
          data.shirt_color_obj?.id || data.shirt_color
        ),
        tie: arrayStringTieType.indexOf(data.tie_type_obj?.id || data.tie_type),
        color: indexOfTieColor == -1 ? 0 : indexOfTieColor,
        suitPackage: arrayStringSuitPackage.indexOf(
          data.more_item_obj?.id || data.more_item
        ),
        price: data.price,
      };
    },
    getSuitMasterData: async function() {
      let suitData = JSON.parse(localStorage.getItem("suitData"));
      if (!suitData) {
        try {
          const res = await api.Suit.getSuitBuider({ page: 1, limit: 1000 });
          if (!res.results || res.results.length === 0) {
            alert("Some thing went wrong , no data to display");
            return;
          }
          const reFormat = {
            suits: [],
            shirts: [],
            ties: {
              types: [],
              colors: [],
            },
            suitPackages: [],
          };

          res.results.forEach((i) => {
            i.price= (+i.price).toFixed(2)
            switch (i.item_type) {
              case SuitDataRes.SUITS:
                reFormat.suits.push(i);
                return;
              case SuitDataRes.SHIRTS:
                reFormat.shirts.push(i);
                return;
              case SuitDataRes.TIES:
                reFormat.ties.types.push(i);
                return;
              case SuitDataRes.SUIT_PACKAGES:
                reFormat.suitPackages.push(i);
                return;
              case SuitDataRes.TIE_COLORS: {
                const color = { ...i };
                color.rgb = JSON.parse(color.rgb);
                reFormat.ties.colors.push(color);
                return;
              }
            }
          });
          suitData = reFormat;
          localStorage.setItem("suitData", JSON.stringify(suitData));
        } catch (error) {
          alert(error);
        }
      }
      this.suitMasterData = suitData;
    },
  },
  computed: {
    fullSuit() {
      if (!this.suitMasterData || !this.suitCombo) return;

      const suit = this.suitMasterData.suits[this.suitCombo.suit];
      const shirt = this.suitMasterData.shirts[this.suitCombo.shirt];
      const tie = this.suitMasterData.ties.types[this.suitCombo.tie];
      const color = this.suitMasterData.ties.colors[this.suitCombo.color];

      const suitPackage = this.suitMasterData.suitPackages[
        this.suitCombo.suitPackage
      ];
      const price =
        +suit.price + +shirt.price + +tie.price + +suitPackage.price;
      const priceNotShoe = +suit.price + +shirt.price + +tie.price;
      return {
        suit,
        shirt,
        tie,
        color,
        suitPackage,
        price,
        priceNotShoe,
      };
    },
  },
  created: function() {
    window.scrollTo(0, 0);
  },
};
