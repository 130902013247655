<template>
  <div v-if="suit" class="complete-suit">
    <div class="full-suit-comp">
      <img
        class="full-suit-comp__base-image"
        :src="suit.shirt.part"
        alt="shirt"
        v-if="suit.shirt && suit.shirt.part"
      />
      <div
        class="tie-component"
        :style="{
          backgroundColor: suit.color.hex,
          mask: 'url(' + suit.tie.part + ') no-repeat center / contain',
          '-webkit-mask':
            'url(' + suit.tie.part + ') no-repeat center / contain',
        }"
        v-if="suit.tie && suit.tie.part"
      ></div>
      <img
        :src="suit.suit.part"
        alt="suit"
        v-if="suit.suit && suit.suit.part"
      />
      <img src="../assets/img/suits/head.png" alt="head" />
      <img
        v-if="suit.suitPackage && suit.suitPackage.part"
        :src="suit.suitPackage.part"
        :style="{ top: '-4px' }"
        alt="package"
      />
    </div>
    <div class="suit-controls">
      <div>
        <div class="suit-controls__suit-data">
          Your selections <v-icon small>mdi-chevron-down</v-icon> <br />
          {{ suit.suit.name + " Suit ($" + suit.suit.price + ")" }}<br />
          <p v-if="suit.shirt.price != 0">
            {{ suit.shirt.name + " Shirt (+ $" + suit.shirt.price + ")" }}
            <br />
          </p>
          <p v-if="suit.tie.price != 0">
            {{ suit.tie.name + " (+ $" + suit.tie.price + ")" }}
            <br />
          </p>
          <p v-if="suit.tie.price != 0">{{ suit.color.name }} Tie <br /></p>

          <p v-if="suit.suitPackage.price != 0" class="text-gray">
            {{
              suit.suitPackage.name +
              " Shoes, Socks & Belt (+ $" +
              suit.suitPackage.price +
              ")"
            }}<br />
          </p>
          <p class="total-price text-blue-dark">
            TOTAL PRICE: ${{ suit.price.toFixed(2) }}
          </p>
        </div>
      </div>
      <hr />
      <input type="hidden" id="testing-code" />
      <div class="buttons-container">
        <div class="button-box" @click="returnEdit()">
          <v-icon x-large>mdi-pencil</v-icon>
          Edit suit
        </div>
        <div class="button-box">
          <ShareNetwork
            network="facebook"
            :url="urlSharing"
            title=""
            hashtags=""
          >
            <v-icon x-large>mdi-upload-outline</v-icon>
            <span> Share suit </span>
          </ShareNetwork>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShirtPart from "../assets/img/shirts_and_ties/White.png";
import SuitPart from "../assets/img/suits/Black.png";
import suitMixin from "../mixins/suitmixin";

export default {
  props: ["suit"],
  mixins: [suitMixin],

  data() {
    return {
      DefaultSuit: {
        shirt: ShirtPart,
        suit: SuitPart,
      },
    };
  },

  methods: {
    returnEdit() {
      this.$emit("edit");
    },
  },

  computed: {
    urlSharing: function () {
      return window.location.href;
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #22394d;

.text-blue-dark {
  color: #304251 !important;
  font-weight: 500;
}
.text-gray {
  color: rgba($color: #000000, $alpha: 0.7);
  font-weight: 500;
}
hr {
  margin: 30px 0;
}

.complete-suit {
  display: grid;
  justify-content: center;
}
.full-suit-comp {
  position: relative;
  margin-bottom: 20px;
  width: 368px;
  height: 552px;
  &__base-image {
    position: relative !important;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
  }
}

.tie-component {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.suit-controls {
  &__suit-data {
    text-align: center;
  }
  .suit-controls__suit-data {
    color: rgba($color: #000000, $alpha: 0.87);
    p {
      margin: 0;

      &.total-price {
        color: #22394d;
        margin-top: 10px;
        letter-spacing: 1px;
      }
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: space-around;

  .button-box {
    display: flex;
    flex-direction: column;
    color: $blue;
    cursor: pointer;
    i {
      color: $blue;
    }

    a {
      color: $blue;
      text-decoration: none;
      display: grid;
    }
  }
}
@media only screen and (max-width: 480px) {
  .full-suit-comp {
    width: 273px;
    height: 417px;
  }
}
</style>
