<template>
  <div class="view-suit">
    <div v-if="suitCombo">
      <v-container class="mb-6 pa-0 text-center">
        <v-row align="start" no-gutters>
          <v-col cols="12" sm="12" md="4" offset-md="4">
            <CompleteSuit :suit="fullSuit" @edit="goBack()" />
          </v-col>
          <v-col cols="12" class="read-to-by-now">
            <span>Ready to buy now?</span>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              depressed
              x-large
              @click="onContinue()"
              class="groom-btn dark continue box-shadow"
            >
              CONTINUE
            </v-btn>
          </v-col>
          <v-col cols="12" class="have-any-questions">
            <span>Have any questions?</span>
          </v-col>
          <v-col cols="12">
            <v-btn
              depressed
              x-large
              class="white--text book-appointment box-shadow"
              @click="bookAppointment()"
            >
              BOOK APPOINTMENT
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div></div>
    <div v-if="loading" class="loading">
      <v-progress-circular
        :size="50"
        color="black"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!suitCombo && !loading" class="nodata">
      <p>No data to display...</p>
    </div>
  </div>
</template>

<script>
import CompleteSuit from "../components/CompleteSuit";
import EventBus from '../eventBus';

// mixin
import suitMixin from "../mixins/suitmixin";

export default {
  mixins: [suitMixin],

  data: () => ({
    suitID: "",
    loading: true,
  }),

  components: {
    CompleteSuit,
  },

  methods: {
    onContinue() {
      const query = (() => {
        return {
          suitId: this.suitId,
          ...(this.$route.query.userId
            ? { userId: this.$route.query.userId }
            : {}),
        };
      })();
      this.$router.push({
        path: "suit-fitted",
        query: query,
      });
    },
    goBack() {
      this.$router.push({
        path: "/suit",
        query: {
          suitId: this.suitId,
          ...(this.$route.query.userId
            ? { userId: this.$route.query.userId }
            : {}),
        },
      });
    },
    updatetieColor() {
      const paths = document.getElementsByTagName("path");
      if (!this.fullSuit.color || !paths || !paths[0]) {
        return;
      }
      paths[0].style.fill = this.fullSuit.color.hex;
    },
    bookAppointment() {
      EventBus.$emit("openDialogAppointment")
    },
  },

  computed: {
    shareUrl() {
      let currentUrl = window.location.href;
      currentUrl =
        currentUrl.indexOf("?") < 0
          ? currentUrl
          : currentUrl.substring(0, currentUrl.indexOf("?"));
      currentUrl =
        currentUrl +
        `?suit=${this.suitCombo.suit}&shirt=${this.suitCombo.shirt}&tie=${this.suitCombo.tie}&color=${this.suitCombo.color}`;
      return currentUrl;
    },
  },
  async beforeMount() {
    this.suitId = this.$route.query.suitId;
    if (!this.suitId) {
      this.$router.push({ path: "/suit" });
      return;
    }
    this.loading = true;
    await this.getSuitMasterData();
    await this.createSuitCombo();

    const el = this;
    await new Promise((resolve) => {
      setTimeout(function() {
        el.loading = false;
      }, 500);
    });
  },
  watch: {
    fullSuit: function(val) {
      if (!val) return;
      this.updatetieColor();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}
.continue {
  font-family: "Roboto" !important;
  font-weight: 500;
  font-size: 16px !important;
  margin-top: 0px !important;
  max-width: 100%;
}
.read-to-by-now {
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
}
.have-any-questions {
  margin-bottom: 15px;
}
.view-suit {
  height: 100%;
  .loading,
  .nodata {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .loading {
    background-color: #00000038;
    position: fixed;
    top: 0;
  }
  .book-appointment {
    height: 40px;
    padding: 10px 42px !important;
    background: #22394d !important;
    border-radius: 0px;
    max-width: 100%;
    min-width: 92px;
    width: 250px;
    font-family: "Roboto" !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
}
</style>
